import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, Tooltip } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';

import getTooltipValue from '~/utils/getTooltipValue';
import { getShortNumber } from '~/utils/getShortNumber';

const useStyles = makeStyles(() => ({
  infoCard: {
    cursor: 'pointer',
    '&:hover>div': {
      background: 'rgba(54, 166, 253, 0.15)',
    },
  },
  value: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },
  container: {
    padding: '0.625rem',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
  },
}));

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    margin: 10,
  },
}));

const InfoCard = ({
  loading = false,
  className = '',
  title,
  value,
  fullTitle,
  tooltipText,
}) => {
  const classes = useStyles();
  const tooltipClass = useTooltipStyles();
  const tooltip = `${tooltipText || fullTitle || title}: ${getTooltipValue(
    value,
  )}`;

  return (
    <Tooltip classes={tooltipClass} placement="top" title={tooltip}>
      <div className={clsx('flex-auto mb-3', classes.infoCard, className)}>
        <div
          className={clsx(
            'w-full bg-white flex flex-col justify-center rounded-lg',
            classes.container,
          )}
        >
          {title}
          <span className={`font-bold ${classes.value}`}>
            {loading || ((value !== null || value !== '00:00') && !value) ? (
              <Skeleton height="100%" />
            ) : (
              getShortNumber(value)
            )}
          </span>
        </div>
      </div>
    </Tooltip>
  );
};

InfoCard.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fullTitle: PropTypes.string,
  tooltipText: PropTypes.string,
};

InfoCard.defaultProps = {
  loading: false,
  className: '',
  fullTitle: '',
  tooltipText: '',
};

export default InfoCard;
