/* eslint-disable prettier/prettier */
import { makeStyles, Tooltip } from '@material-ui/core';
import { capitalize, cloneDeep } from 'lodash';
import React, {useCallback, useEffect} from 'react';
import { useIsOver } from '~/hooks/useIsOver';
import { useDispatch, useSelector } from 'react-redux';
import { setTimeStep } from '~/store/timeStep';
import { RANGE_TYPE } from '../constants';


const useStyles = makeStyles(theme => ({
  viewDurationSettersWrapper: {
    backgroundColor: 'white',
    position: 'relative',
    zIndex: 1,
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    width: 'max-content',
    boxShadow: '0px 4px 20px rgb(0 0 0 / 15%)',
    borderRadius: 6,
  },
  viewDurationSetter: {
    color: '#CBC9C9',
    cursor: 'pointer',
    fontSize: '0.75rem',
    padding: '3px 10px',
    borderRadius: 6,
    background: 'rgba(54, 166, 253, 0.05)',
    '&.active': {
      background: 'rgba(54, 166, 253, 0.15)',
      color: '#333',
    },
  },
}));

export default function TimeStep({ visitsDateValue, onClick }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const selectedTimeStep = useSelector(state => state.timeStep.value)

  const handleTimeStepSelect = useCallback(step => {
    dispatch(setTimeStep(selectedTimeStep.map(item =>
      item.name === step
        ? { ...item, isSelected: true }
        : { ...item, isSelected: false },
    )))

    // setCurrentStep(step)
    
    if(onClick){
      onClick(step)
    }
  }, [dispatch, onClick, selectedTimeStep]);

  const setTimeStepVisibleValue = useCallback((step, value) => {
    dispatch(setTimeStep(selectedTimeStep.map(item =>
      item.name === step
        ? { ...item, visible: value }
        : { ...item, visible: !value },
    )))
  }, [dispatch, selectedTimeStep]);

  const setAllTimeStepVisible = useCallback(() => {
    dispatch(setTimeStep(selectedTimeStep.map(item => ({ ...item, visible: true }))))
  }, [dispatch, selectedTimeStep]);

  useEffect(() => {
    if(visitsDateValue[1].diff(visitsDateValue[0], 'day') <= 70){
      if(selectedTimeStep.some(s => !s.visible)){
        setAllTimeStepVisible()
      }
    } else if(selectedTimeStep.find(t => t.name === RANGE_TYPE.DAY).visible){
        const copy = cloneDeep(selectedTimeStep)
        copy.find(s => s.name === RANGE_TYPE.DAY).visible = false
    
        if(onClick){
          onClick(RANGE_TYPE.WEEK)
        }

        dispatch(setTimeStep(copy.map(item =>
          item.name === RANGE_TYPE.WEEK
            ? { ...item, isSelected: true }
            : { ...item, isSelected: false },
        )))
      }
  }, [setTimeStepVisibleValue, handleTimeStepSelect, setAllTimeStepVisible, selectedTimeStep, onClick, dispatch, visitsDateValue])

  return (
    <>
      {selectedTimeStep.map(({ name, isSelected, visible }) => {
        if(visible){
          return <button
            key={name}
            type="button"
            onClick={() => visible && handleTimeStepSelect(name)}
            className={classes.viewDurationSetter}
            style={{
            color: isSelected ? '#333' : '#CBC9C9',
            background: isSelected
              ? 'rgba(54, 166, 253, 0.15)'
              : 'rgba(54, 166, 253, 0.05)',
          }}
          >
            {capitalize(name)}
          </button>
        }


        return <Tooltip
          key={name} 
          placement="top" 
          title="To view the daily chart, please choose a period that does not exceed 2 calendar months"
        >
          <button
            type="button"
            onClick={() => visible && handleTimeStepSelect(name)}
            className={classes.viewDurationSetter}
            style={{
                  color: isSelected ? '#333' : '#CBC9C9',
                  background: isSelected
                    ? 'rgba(54, 166, 253, 0.15)'
                    : 'rgba(54, 166, 253, 0.05)',
                }}
          >
            {capitalize(name)}
          </button>
               </Tooltip>

      })}
    </>
  );
}
