/* eslint-disable prettier/prettier */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { Doughnut, Line } from 'react-chartjs-2';
import Skeleton from 'react-loading-skeleton';

import moment from 'moment';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { isEqual } from 'lodash';
import { makeStyles, Tooltip } from '@material-ui/core';

import {
  setVisitsTooltipInfo,
  updateVisitsTooltipInfo,
} from '~/store/visitsTooltip';
import Page from '~/components/shared/Page';
import Header from '~/components/layouts/DashboardLayout/components/Header';
import {
  getLocationsViaList,
  getMultiLocationStats,
  getMultitourMenuClickStats,
  getMultitourViewDurationStats,
  getMultitourVisitsStats,
} from '~/services/api';
import { formatDateFromSeconds } from '~/utils/helper';
import { RecentlyViewedType, saveRecentlyViewed } from '~/services/clientApi';
import * as recentlyViewed from '~/store/recentlyViewed';
import size from '~/utils/size';
import { defaultTimeRange } from '~/utils/defaultTimeRange';

import {
  changeVisible,
  trySetRange,
  setRange,
  setUseCustomRange,
} from '~/store/datePicker';
import LocationsGalleryView from '~/views/private/gallery/LocationsGalleryView';
import { TOOLTIP_TYPE, DEFAULT_MULTILOCATION_STATS } from './constants';
import {
  calculateChartSize,
  calculateEventsInIntervals,
  calculateImgSize,
  filterStatsByDate,
  formatLabels,
} from './utils';
import {
  GraphTooltip,
  InfoCard,
  MultiDateRangePicker,
  TimeStep,
  ViewsByDevicesInfoCard,
} from './Components';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    color: '#333',
    paddingLeft: '2.8em',
    paddingBottom: '0px',
  },
  backIcon: {
    marginRight: '14px',
    display: 'block',
    width: '25px',
    height: '24px',
    backgroundImage: 'url(/static/icons/arrow-back.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',
    cursor: 'pointer',

    '&:hover': {
      backgroundImage: 'url(/static/icons/arrow-back-active.svg)',
    },
  },
  upload: {
    background: 'rgba(54, 166, 253, 0.15)',
    borderRadius: 6,
    padding: '3px 4px',
    width: '24px',
    display: 'flex',
    marginLeft: 6,
    height: '24px',
    cursor: 'pointer',

    [theme.breakpoints.up('hiRes')]: {
      width: '1.4rem',
      height: '1.4rem',
      padding: '0.28rem',
    },
  },
  h12: {
    height: '370px',

    [theme.breakpoints.up('hiRes')]: {
      height: '12vw',
    },
  },
  launchTour: {
    display: 'inline-flex',
    paddingInline: '6.5px',
    paddingBlock: '1.5px 2px',
    marginLeft: '18px',
    borderRadius: '5px',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 4px',
    outline: 'none',
    border: '0px',
    textOverflow: 'ellipsis',
    fontSize: '12px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    background: '#36A6FD',
    color: 'white',
    verticalAlign: 'text-top',
    marginTop: '6px',
  },
}));

const ctx = document.createElement('canvas').getContext('2d');
const gradient = ctx.createLinearGradient(0, 0, 0, 250);
gradient.addColorStop(0, 'rgba(54,166,253,0.6)');
gradient.addColorStop(1, 'rgba(54,166,253,0)');

const MultilocationOverviewView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const classes = useStyles();
  const visitsDateValue = useSelector(state => state.datePicker.range);
  const currentStep = useSelector(state => state.timeStep.value).find(
    ts => ts.isSelected,
  ).name;

  const refVisits = useRef();
  const refDoughnut = useRef();
  const refVisitsScroll = useRef();
  const initialVisitsDateValue = useRef(visitsDateValue);

  let { listId, companyName } = useParams();
  listId = decodeURIComponent(listId);
  companyName = decodeURIComponent(companyName);

  const [filteredVisitStats, setFilteredVisitStats] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const summaryStats = useAsync(async () => {
    if (!isEqual(visitsDateValue, initialVisitsDateValue.current)) {
      setIsLoading(true);
      dispatch(changeVisible(false));

      return getMultiLocationStats(
        listId,
        companyName,
        visitsDateValue[0].unix() * 1000,
        visitsDateValue[1].unix() * 1000,
      )
        .then(result => {
          setIsLoading(false);
          return result;
        })
        .catch(() => DEFAULT_MULTILOCATION_STATS);
    }
    return null;
  }, [dispatch, listId, companyName, visitsDateValue]);

  const listLocations = useAsync(
    async () => getLocationsViaList(listId, companyName).catch(() => []),
    [listId, companyName],
  );

  const multitourViewDurationStats = useAsync(async () => {
    if (listId)
      return getMultitourViewDurationStats(listId, companyName).catch(() => []);
    return null;
  }, [listId]);

  const multitourVisitsStats = useAsync(async () => {
    if (listId)
      return getMultitourVisitsStats(listId, companyName).catch(() => []);
    return null;
  }, [listId]);

  const multitourMenuClickStats = useAsync(async () => {
    if (listId && visitsDateValue.length === 2) {
      const stats = await getMultitourMenuClickStats(
        listId,
        companyName,
        visitsDateValue[0].unix() * 1000,
        visitsDateValue[1].unix() * 1000,
      ).catch(() => []);

      return stats;
    }

    return Promise.resolve({ value: [], loading: true });
  }, [listId, companyName, visitsDateValue]);

  const mainLocation = useMemo(() => {
    if (listLocations.value) {
      return (
        listLocations.value.find(v => v.description.IS_MAIN) ||
        listLocations.value[0]
      );
    }
  }, [listLocations]);

  const mobileVisitsPercent = useMemo(() => {
    if (summaryStats.value == null) return 0;

    return (
      (100 * summaryStats.value.totalVisitsMobile) /
      (summaryStats.value.totalVisitsMobile + summaryStats.value.totalVisits)
    );
  }, [summaryStats.value]);

  const desktopVisitsPercent = useMemo(() => {
    return 100 - mobileVisitsPercent;
  }, [mobileVisitsPercent]);

  const handleSavePdf = useCallback((canvas, isButtonsClicks = true) => {
    const imgData = canvas.toDataURL('image/png', 1);
    const docDefinition = {
      content: [
        {
          text: isButtonsClicks ? 'Button Clicks Summary' : 'Views Summary',
          style: 'header',
        },
        {
          text: isButtonsClicks
            ? "\nInformation about the user's actions with the viewed tour"
            : '\nTotal number of views for the specified period',
          fontSize: 10,
          margin: [0, 0, 0, 10],
        },
        calculateImgSize(imgData, canvas.width, canvas.height),
      ],
      styles: {
        header: {
          fontSize: 23,
          bold: true,
        },
      },
      pageOrientation: 'landscape',
    };

    pdfMake.createPdf(docDefinition).download();
  }, []);

  const changeVisitsTimestampHandler = useCallback(() => {
    refVisitsScroll.current.scrollLeft = 0;
  }, []);

  const resetDatePicker = useCallback(() => {
    dispatch(setRange(initialVisitsDateValue.current));
    dispatch(setUseCustomRange(false));
  }, [dispatch]);

  useEffect(() => {
    resetDatePicker();
  }, [listId, resetDatePicker]);

  useEffect(() => {
    if (
      !multitourVisitsStats.loading &&
      multitourVisitsStats.value &&
      multitourVisitsStats.value[0]
    ) {
      dispatch(
        trySetRange(
          defaultTimeRange(moment(multitourVisitsStats.value[0].eventDate)),
        ),
      );
    }
  }, [dispatch, multitourVisitsStats]);

  useEffect(() => {
    if (
      !multitourVisitsStats.loading &&
      multitourVisitsStats.value &&
      visitsDateValue
    ) {
      setFilteredVisitStats(
        filterStatsByDate(multitourVisitsStats.value, visitsDateValue).slice(),
      );
    }
  }, [multitourVisitsStats, visitsDateValue]);

  useEffect(() => {
    if (multitourVisitsStats.value != null && visitsDateValue) {
      const dataVisits = calculateEventsInIntervals({
        data: multitourVisitsStats.value
          .slice()
          .map(({ eventDate, eventsCount }) => {
            return {
              eventDate,
              value: eventsCount,
            };
          }),
        range: currentStep,
        start: visitsDateValue[0].format('YYYY-MM-DD'),
        end: visitsDateValue[1].format('YYYY-MM-DD'),
      });

      setFilteredVisitStats(
        dataVisits.reverse().map(({ eventDate, value }) => ({
          eventDate,
          eventsCount: value,
        })),
      );
    }
  }, [currentStep, multitourVisitsStats, visitsDateValue]);

  useEffect(() => {
    saveRecentlyViewed({ type: RecentlyViewedType.TOUR, listId })
      .then(res => dispatch(recentlyViewed.push(res)))
      .catch(console.error);
  }, [dispatch, listId]);

  useEffect(() => {
    initialVisitsDateValue.current = visitsDateValue;
  }, [visitsDateValue]);

  if (
    !multitourViewDurationStats ||
    !multitourViewDurationStats.value ||
    !multitourVisitsStats.value ||
    !listLocations.value
  ) {
    return <React.Fragment />;
  }

  return (
    <Page className={classes.root} title="Overview">
      <Header>
        <div
          style={{ display: 'flex', alignItems: 'center', color: '#6C6C6C' }}
        >
          <div
            role="button"
            onClick={() => navigate(-1)}
            className={classes.backIcon}
          />
          <div>
            <div style={{ fontSize: '1.5rem' }}>
              <span style={{ display: 'inline-flex', color: '#333' }}>
                {listId}
              </span>
              <a
                className={classes.launchTour}
                target="_blank"
                href={`${process.env.SNOWPACK_PUBLIC_PLAYER_URL}/?locationId=${mainLocation.id}`}
                rel="noreferrer"
              >
                Launch the tour
              </a>
            </div>
            <div style={{ fontWeight: 400, fontSize: '0.875rem' }}>
              Virtual Tour locations summary
            </div>
          </div>
        </div>
      </Header>
      <div
        className="w-full flex flex-wrap"
        style={{
          width: 'calc(100% + 4px)',
          marginLeft: '-4px',
          paddingTop: '30px',
        }}
      >
        <InfoCard
          className="px-1"
          title="Total Views"
          loading={isLoading}
          value={
            summaryStats.value === null
              ? null
              : summaryStats.value.totalVisits +
                  summaryStats.value.totalVisitsMobile !==
                0
              ? summaryStats.value.totalVisits +
                summaryStats.value.totalVisitsMobile
              : -1
          }
        />
        <InfoCard
          className="px-1"
          title="Last Month Views"
          loading={isLoading}
          value={
            summaryStats.value === null
              ? null
              : summaryStats.value.last30DaysVisits +
                  summaryStats.value.last30DaysVisitsMobile !==
                0
              ? summaryStats.value.last30DaysVisits +
                summaryStats.value.last30DaysVisitsMobile
              : -1
          }
        />
        <InfoCard
          className="px-1"
          title="Total Duration"
          loading={isLoading}
          value={
            summaryStats.value === null
              ? null
              : summaryStats.value.totalDuration !== 0
              ? formatDateFromSeconds(summaryStats.value.totalDuration)
              : '00:00'
          }
          tooltipText="Total Duration of viewing of the Virtual Tour"
        />
        <InfoCard
          className="px-1"
          title="Conversion"
          loading={isLoading}
          value={
            summaryStats.value === null
              ? null
              : summaryStats.value.totalCustomButtonClicks !== 0
              ? summaryStats.value.totalCustomButtonClicks
              : -1
          }
          tooltipText="Amounts of clicks to the Lease Now button at the bottom right corner"
        />
        <InfoCard
          className="px-1"
          title="Custom Button Clicks"
          loading={isLoading}
          value={
            summaryStats.value === null
              ? null
              : summaryStats.value.totalHotspotButtonClicks !== 0
              ? summaryStats.value.totalHotspotButtonClicks
              : -1
          }
          tooltipText="Amounts of clicks on the all buttons inside informational hotspots"
        />
        <ViewsByDevicesInfoCard
          loading={isLoading}
          mobilePercent={mobileVisitsPercent}
          desktopPercent={desktopVisitsPercent}
          mobileCount={
            summaryStats.value && summaryStats.value.totalVisitsMobile
          }
          desktopCount={summaryStats.value && summaryStats.value.totalVisits}
        />
      </div>

      <div
        className="w-full flex mt-1 mb-2"
        style={{ borderRadius: '10px', minHeight: '273px' }}
      >
        <div
          className="w-1/2 h-auto mr-1 bg-white relative"
          style={{
            padding: '0 20px 10px',
            borderRadius: '10px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          }}
        >
          <div
            className="font-bold bg-white flex justify-between flex-wrap"
            style={{ paddingTop: 18 }}
          >
            <div
              className="flex"
              style={{
                marginBottom: 18,
                fontSize: '0.75rem',
                alignItems: 'center',
              }}
            >
              <Tooltip
                placement="top"
                title="Amount of views applied range of times"
              >
                <span>Views Summary</span>
              </Tooltip>
              <div
                role="button"
                className={classes.upload}
                onClick={() =>
                  handleSavePdf(refVisits.current.chartInstance.canvas, false)
                }
              >
                <img src="/static/icons/upload.svg" alt="Upload" />
              </div>
              <div
                className="flex"
                style={{ marginLeft: '20px', fontSize: '12px' }}
              >
                <TimeStep
                  visitsDateValue={visitsDateValue}
                  onClick={changeVisitsTimestampHandler}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '1rem',
              }}
            />
            <MultiDateRangePicker resetDatePicker={resetDatePicker} />
          </div>

          <div
            className={`w-full relative flex flex-col ${classes.h12}`}
            ref={refVisitsScroll}
            style={{
              overflow: 'auto',
            }}
          >
            <div
              style={{
                position: 'relative',
                height: '100%',
                minWidth:
                  filteredVisitStats.length * calculateChartSize(currentStep),
                overflowY: 'overlay',
              }}
            >
              {filteredVisitStats.length !== 0 ? (
                <Line
                  ref={refVisits}
                  data={{
                    datasets: [
                      {
                        backgroundColor: gradient,
                        lineTension: 0.01,
                        borderColor: '#36A6FD',
                        pointBackgroundColor: 'transparent',
                        pointBorderColor: 'transparent',
                        pointRadius: size(15),
                        pointHoverRadius: size(15),
                        borderWidth: 1,
                        parsing: false,
                        data: filteredVisitStats.map(item => item.eventsCount),
                        label: '',
                      },
                    ],
                    labels: formatLabels(filteredVisitStats, currentStep),
                  }}
                  options={{
                    normalized: true,
                    animation: false,
                    cornerRadius: size(20),
                    layout: {
                      padding: {
                        right: size(13),
                      },
                    },
                    legend: { display: false },
                    maintainAspectRatio: false,
                    responsive: true,
                    devicePixelRatio: window.devicePixelRatio * 2,

                    hover: {
                      onHover(e, [chart]) {
                        if (!chart) {
                          dispatch(
                            updateVisitsTooltipInfo({
                              show: false,
                              showDashes: false,
                              showLabel: false,
                              showCircle: false,
                            }),
                          );
                          return;
                        }

                        const value = filteredVisitStats[chart._index];

                        dispatch(
                          setVisitsTooltipInfo({
                            showCircle: true,
                            showDashes: true,
                            type: TOOLTIP_TYPE.SMALL,
                            show: true,
                            value,
                            label: chart._xScale.ticks[chart._index],
                            smallTooltipText: `${value.eventsCount}`,
                            dashesHeight: `${
                              refVisits.current.chartInstance.height - 20
                            }px`,
                            showLabel: true,
                            tooltipLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              20,
                            tooltipTop: chart._view.y + 28,
                            circleLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              10,
                            circleTop: chart._view.y + 50,
                            dashesLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              20,
                            dashesTop:
                              e.target.parentElement.parentElement.offsetTop,
                            labelLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              20,
                            labelBottom: 15,
                          }),
                        );
                      },
                    },
                    tooltips: {
                      enabled: false,
                      intersect: false,
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontSize: size(8),
                            reverse: true,
                          },
                          categoryPercentage: 0.97,
                          barPercentage: 0.97,
                          barThickness: 28,
                          scaleLabel: {
                            display: true,
                            labelString: 'Date',
                          },
                        },
                      ],
                      yAxes: [
                        {
                          ticks: {
                            fontStyle: 'bold',
                            fontColor: '#000',
                            stepSize: 250,
                            beginAtZero: true,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: 'Views',
                          },
                        },
                      ],
                    },
                  }}
                />
              ) : (
                <Skeleton height="99%" />
              )}
            </div>
          </div>
          <GraphTooltip store="visitsTooltip" />
        </div>

        <div
          className="w-1/2 h-auto mr-1 bg-white relative"
          style={{
            padding: '0 20px 10px',
            borderRadius: '10px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          }}
        >
          <div
            className="font-bold bg-white flex justify-between flex-wrap"
            style={{ paddingTop: 18 }}
          >
            <div
              className="flex"
              style={{
                marginBottom: 18,
                fontSize: '0.75rem',
                alignItems: 'center',
              }}
            >
              Button Clicks Summary
              <div
                role="button"
                className={classes.upload}
                onClick={() =>
                  handleSavePdf(refDoughnut.current.chartInstance.canvas)
                }
              >
                <img src="/static/icons/upload.svg" alt="Upload" />
              </div>
            </div>
          </div>

          <div
            className={`w-full relative flex flex-col ${classes.h12}`}
            style={{
              overflow: 'auto',
            }}
          >
            <div
              style={{
                position: 'relative',
                height: '100%',
                overflowY: 'overlay',
              }}
            >
              <Doughnut
                ref={refDoughnut}
                data={{
                  datasets: [
                    {
                      backgroundColor: (
                        multitourMenuClickStats.value || []
                      ).map(x => x.color),
                      data: (multitourMenuClickStats.value || []).map(
                        x => x.count,
                      ),
                      datalabels: {
                        labels: {
                          value: {},
                          name: {
                            align: 'end',
                            anchor: 'end',
                            offset: -1,
                            font: { size: 1 },
                            padding: 0,
                            backgroundColor: '#A0A0A0',
                            color: '#A0A0A0',
                            formatter() {
                              return '................................................................................................';
                            },
                            rotation(value) {
                              const sum = value.dataset.data.reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue,
                              );
                              const position =
                                value.dataset.data
                                  .slice(0, value.dataIndex + 1)
                                  .reduce(
                                    (accumulator, currentValue) =>
                                      accumulator + currentValue,
                                  ) -
                                value.dataset.data[value.dataIndex] / 2;
                              return (position / sum) * 360 - 90;
                            },
                          },
                        },
                      },
                    },
                  ],
                  labels: (multitourMenuClickStats.value || []).map(
                    x => x.name,
                  ),
                }}
                options={{
                  maintainAspectRatio: false,
                  devicePixelRatio: window.devicePixelRatio * 2,
                  legend: {
                    display: true,
                    position: document.body.offsetWidth > 1500 ? 'left' : 'top',
                    labels: {
                      fontSize: size(12),
                    },
                  },
                  layout: {
                    padding: 50,
                  },
                  scales: {
                    xAxes: [{ display: false }],
                    yAxes: [{ display: false }],
                  },
                  pieceLabel: {
                    render: 'label',
                    fontColor: '#000',
                    position: 'outside',
                  },
                  plugins: {
                    datalabels: {
                      anchor: 'end',
                      align: 'end',
                      offset: 22,
                      color: '#A0A0A0',
                      font: {
                        size: size(12),
                      },
                    },
                  },
                }}
                plugins={[
                  ChartDataLabels,
                  {
                    beforeInit(chart) {
                      // eslint-disable-next-line func-names
                      chart.legend.afterFit = function () {
                        // eslint-disable-next-line react/no-this-in-sfc
                        this.height += 50;
                        // eslint-disable-next-line react/no-this-in-sfc
                        this.legendHitBoxes.forEach(element => {
                          element.left = 0;
                        });
                      };
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex">
        <div
          className="w-7/12 flex gap-4"
          style={{ padding: '10px 0', fontSize: '1.5rem', fontWeight: 'bold' }}
        >
          {`${listLocations.value.length} Location${
            listLocations.value.length > 1 ? 's' : ''
          }`}
        </div>
      </div>
      <div className="w-full flex">
        <LocationsGalleryView locations={listLocations.value} />
      </div>
    </Page>
  );
};

export default MultilocationOverviewView;
