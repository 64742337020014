import React, { useEffect, useRef, useState } from 'react';
import { useAsync } from 'react-use';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { makeStyles, Tooltip } from '@material-ui/core';
import PlayCircleOutlineRounded from '@material-ui/icons/PlayCircleOutlineRounded';

import ImageWithFallback from '~/components/shared/ImageWithFallback';
import { getSummaryLocationStats } from '~/services/api';
import { DEFAULT_LOCATION_STATS } from '~/views/private/overview/constants';
import { formatDateFromSeconds } from '~/utils/helper';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '241px',
    background: '#fff',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    flexDirection: 'column',
    color: '#333',
    cursor: 'pointer',

    [theme.breakpoints.up('hiRes')]: {
      height: '16rem',
    },
  },
  number: {
    letterSpacing: '0.13em',
    fontWeight: 'bold',
    fontSize: '0.875rem',
  },
  prop: {
    fontWeight: 400,
    color: '#616161',
    fontSize: '0.625rem',
  },
  image: {
    width: '100%',
    height: '164px',
    boxShadow: 'inset 0px 4px 10px rgba(0, 0, 0, 0.5)',
    objectFit: 'cover',

    [theme.breakpoints.up('hiRes')]: {
      height: '10.8rem',
    },
  },
  goToLocation: {
    '&:hover': {
      color: '#36A6FD',
    },
  },
  paper: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,

    [theme.breakpoints.up('hiRes')]: {
      padding: '0.65rem',
    },
  },
}));

const BASE_URL = process.env.SNOWPACK_PUBLIC_CDN_ENDPOINT;

function LocationCard({ location }) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [isLoad, setIsLoad] = useState(null);

  const visitsDateValue = useSelector(state => state.datePicker.range);

  const initialVisitsDateValue = useRef(visitsDateValue);

  const summaryStats = useAsync(async () => {
    if (
      visitsDateValue &&
      visitsDateValue.length !== 0 &&
      !isEqual(visitsDateValue, initialVisitsDateValue.current)
    ) {
      return getSummaryLocationStats(
        location.id,
        visitsDateValue[0].unix() * 1000,
        visitsDateValue[1].unix() * 1000,
      )
        .then(result => result)
        .catch(() => DEFAULT_LOCATION_STATS);
    }
  }, [dispatch, location.id, visitsDateValue]);

  useEffect(() => {
    initialVisitsDateValue.current = visitsDateValue;
  }, [visitsDateValue]);

  if (!summaryStats.value) {
    return (
      <div
        style={{
          height: '241px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer',
          justifyContent: 'space-between',
        }}
        role="button"
      >
        <Skeleton height={164} />
        <Skeleton height={57} />
      </div>
    );
  }

  return (
    <>
      <div
        className={classes.root}
        onClick={() => {
          return navigate(`/overview/location/${location.id}`);
        }}
        role="button"
        style={{
          display: isLoad ? 'flex' : 'none',
        }}
      >
        <ImageWithFallback
          className={classes.image}
          src={`${BASE_URL}/${location.description.PREVIEW_IMAGE}`}
          fallbackSrc={`/static/images/no-img-placeholder.png`}
          alt=""
          isLoad={isLoad}
          setIsLoad={setIsLoad}
        />
        <div className={classes.paper}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              style={{
                fontSize: '0,875rem',
                fontWeight: 700,
                textTransform: 'uppercase',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '92%',
              }}
            >
              {location.name}
            </div>
            <Tooltip placement="top" title={'Launch the location'}>
              <a
                href={`${process.env.SNOWPACK_PUBLIC_PLAYER_URL}/?locationId=${location.id}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={e => e.stopPropagation()}
              >
                <PlayCircleOutlineRounded className={classes.goToLocation} />
              </a>
            </Tooltip>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <div className={classes.number}>
                {((summaryStats.value || DEFAULT_LOCATION_STATS).totalVisits ||
                  0) +
                  ((summaryStats.value || DEFAULT_LOCATION_STATS)
                    .totalVisitsMobile || 0)}
              </div>
              <div className={classes.prop}>Total Views</div>
            </div>
            <div>
              <div className={classes.number}>
                {formatDateFromSeconds(
                  (summaryStats.value || DEFAULT_LOCATION_STATS).totalDuration,
                ) || '00:00'}
              </div>
              <div className={classes.prop}>Total Duration</div>
            </div>
            <div>
              <div className={classes.number}>
                {
                  (summaryStats.value || DEFAULT_LOCATION_STATS)
                    .totalCustomButtonClicks
                }
              </div>
              <div className={classes.prop}>Conversions</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

LocationCard.propTypes = {
  location: PropTypes.object.isRequired,
};

export default LocationCard;
