import moment from 'moment';

export function sortByFieldName(fieldName) {
  return (a, b) => (a[fieldName] > b[fieldName] ? 1 : -1);
}

export function timeConverter(timeStamp, format = 'MM/DD/YYYY') {
  return moment(new Date(timeStamp * 1000)).format(format);
}

export function dateConverter(dateString) {
  const [year, month, day] = dateString.split('-');
  return `${month}/${day}/${year}`;
}

export function qBTimeConverter(time, format = 'MM/DD/YYYY') {
  return moment(time).format(format);
}

export function dBDateConverter(inputDate) {
  const date = new Date(inputDate);

  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const year = date.getUTCFullYear();

  return `${month}/${day}/${year}`;
}

export function getTimePeriod(interval) {
  switch (interval) {
    case 'day':
    case 'daily':
      return 'day';
    case 'week':
    case 'weekly':
      return 'week';
    case 'month':
    case 'monthly':
      return 'month';
    case 'year':
    case 'yearly':
      return 'year';
    default:
      throw new Error('Invalid input');
  }
}
