import { createSlice } from '@reduxjs/toolkit';
import { RANGE_TYPE, TIME_STEP } from '~/views/private/overview/constants';

const timeStep = createSlice({
  name: 'timeStep',
  initialState: {
    value: TIME_STEP,
  },
  reducers: {
    setTimeStep: (state, action) => {
      return { ...state, value: action.payload };
    },
  },
});

export const { setTimeStep } = timeStep.actions;

export default timeStep.reducer;
