import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useAsync } from 'react-use';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Skeleton from 'react-loading-skeleton';

import clsx from 'clsx';
import {
  Box,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Tooltip,
  Collapse,
  TableFooter,
  TablePagination,
  MenuItem,
  FormControl,
  Select,
  CircularProgress,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import PlayCircleOutlineRounded from '@material-ui/icons/PlayCircleOutlineRounded';
import Sort from '@material-ui/icons/Sort';
import { isEmpty } from 'lodash';

import { authRoles } from '~/constants';
import { getCompaniesFullStats, buildLocationsForUser } from '~/services/api';
import { getSubscriptionById } from '~/services/stripeApi';
import {
  getQBInvoicesRecId,
  getHostingLastRunTime,
} from '~/services/quickBooksApi';

import StripeDialog from '~/views/private/billings/components/StripeDialog';
import QuickBooksStatusChip from '~/views/private/billings/components/QuickBooksStatusChip';
import {
  sortByFieldName,
  timeConverter,
  dateConverter,
  dBDateConverter,
} from '../utils';

const useStyles = makeStyles(() => ({
  subTitle: {
    fontWeight: 200,
  },
  tableHeaderCell: {
    textAlign: 'center',
    width: '25rem',
    fontWeight: 700,
    fontSize: 'larger',
    background: 'rgba(54, 166, 253, 0.15)',
  },
  tableHeaderArrowCell: {
    textAlign: 'center',
    width: '5rem',
    background: 'rgba(54, 166, 253, 0.15)',
  },
  tableArrowCell: {
    textAlign: 'center',
    width: '5rem',
  },
  tableCell: {
    textAlign: 'center',
    width: '25rem',
  },
  tableCellComment: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '10rem',
    whiteSpace: 'nowrap',
  },
  multilocationName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '20rem',
    whiteSpace: 'nowrap',
  },
  tableCommentCell: {
    borderLeft: '3px solid #363636',
  },
  tableSubRowHeaderLocation: {
    fontWeight: 700,
    paddingLeft: '7rem',
  },
  tableSubRowLocation: {
    paddingLeft: '8rem',
    textAlign: 'left',
  },
  tableSubRowDateCreation: {
    paddingLeft: '8rem',
    textAlign: 'left',
  },
  disabledTour: {
    backgroundColor: 'rgba(245, 150, 150, 0.2)',

    '&:hover': {
      backgroundColor: 'rgba(245, 150, 150, 0.3) !important',
    },
  },
  disabledLocation: {
    backgroundColor: 'rgba(245, 150, 150, 0.2) !important',
  },
  sortIcon: {
    cursor: 'pointer',
    marginLeft: '6px',
    // transition: 'transform 0.3s',
  },
  sortIconAsc: {
    transform: 'rotate(180deg)',
  },
  tableSubRowHeaderPrice: {
    fontWeight: 700,
    paddingLeft: '7rem',
    textAlign: 'center',
  },
  tableSubRowPrice: {
    paddingLeft: '7rem',
    textAlign: 'center',
  },
  tablePropertyCell: {
    fontWeight: 500,
    fontSize: 'large',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '26rem',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
  },
  loadingStyle: {
    height: '78vh',
    width: '94vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  statusCell: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    position: 'relative',
  },
  lastCell: {
    borderBottom: 'none',
  },
  statusImg: {
    width: '1rem',
    marginTop: '0',
    marginLeft: '0.5rem',
  },
  tableRow: {
    '&:nth-child(odd)': {
      backgroundColor: '#f2f2f2',
    },
  },
  tooltip: {
    backgroundColor: 'rgba(204, 204, 220, 0.8)',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0 0 3px 3px rgba(0, 0, 0, 0.1)',
    fontSize: 13,
    textAlign: 'center',
  },
  skeleton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  skeletonContent: {
    textAlign: 'center',
    fontSize: '2rem',
    color: 'grey',
    margin: '20px',
  },
  tourNumber: {
    display: 'inline',
    paddingRight: '1rem',
    fontWeight: '400',
  },
  startTourButtonGreen: {
    color: '#5FCC9C',
  },
  startTourButtonBlue: {
    color: '#36A6FD',
  },
  spinner: {
    color: '#36A6FD',
    marginLeft: '8px',
    verticalAlign: 'middle',
  },
  formControl: {
    marginLeft: '16px',
    marginTop: '-4px',
  },
  logoImg: {
    width: '22px',
    marginLeft: '12px',
    borderRadius: '50%',
  },
  menuItem: {
    '&.Mui-selected': {
      backgroundColor: '#999999 !important',
    },
    '&:hover': {
      backgroundColor: '#777777 !important',
    },
  },
  selectRoot: {
    borderRadius: 4,
    backgroundColor: '#aaabbb',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#666666',
    },
  },
  // dropDown: {
  //   '& .MuiSelect-root': {
  //     borderRadius: 4,
  //     backgroundColor: '#666666',
  //     border: '1px solid #ced4da',
  //     fontSize: 16,
  //     padding: '10px 26px 10px 12px',
  //     '&:focus': {
  //       borderRadius: 4,
  //       borderColor: '#999999',
  //     },
  //   },
  // },
  // footer: {
  //   '& .MuiTablePagination-selectRoot': {
  //     marginLeft: '10px',
  //     marginRight: '22px',
  //   },
  //   '& .MuiSelect-select': {
  //     border: '1px solid rgba(204, 204, 220, 0.2)',
  //     borderRadius: '4px',
  //   },
  //   '& .MuiInputBase-input': {
  //     borderRadius: '4px',
  //   },
  //   'option.MuiTablePagination-menuItem': {
  //     borderRadius: '4px',
  //     backgroundColor: '#36A6FD'
  //   },
  //   '& .MuiMenu-paper': {
  //     borderRadius: '4px',
  //     backgroundColor: '#36A6FD'
  //   },
  // },
}));

const StripesTable = ({ className, ...rest }) => {
  const classes = useStyles();
  const user = useSelector(state => state.auth.user);
  const isAdmin = user.role === authRoles.ADMIN;

  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [openedItemId, setOpenedItemId] = useState(false);
  const [sortOrder, setSortOrder] = useState('desc');
  const [isSortedAsc, setIsSortedAsc] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [lastRunTime, setLastRunTime] = useState(null);

  const [sortOrderByStatus, setSortOrderByStatus] = useState('asc');
  const [isStatusSortedAsc, setIsStatusSortedAsc] = useState(false);
  const [isSortingByStatus, setIsSortingByStatus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const statusOrder = [
    'active',
    'pending',
    'past_due',
    'canceled',
    'trialing',
    'paused',
  ];

  const MONTHLY_PRICE_PER_LOCATION = 4;
  const ANNUALLY_PRICE_PER_LOCATION = 48;

  const toggleOpenItem = (event, id) => {
    event.stopPropagation();

    if (openedItemId === id) {
      setOpenedItemId(null);
    } else {
      setOpenedItemId(id);
    }
  };

  const locations = useAsync(async () => {
    return isAdmin
      ? await getCompaniesFullStats()
      : await buildLocationsForUser(user.company);
  }, [isAdmin, refresh, status]);

  const multiLocations = useMemo(() => locations.value || [], [locations]);

  const filteredByCompanyName = useMemo(
    () => multiLocations.sort(sortByFieldName('companyName')),
    [multiLocations],
  );

  const handleStateChangeInPaymentDialog = async subs_id => {
    try {
      setIsLoading(true);
      setRefresh(prevState => !prevState);
    } catch (error) {
      console.error('Error updating the database: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCustomerData = async tourData => {
    const {
      subscription_id,
      qb_recurring_transaction_id,
      qb_recurring_data_ref,
    } = tourData.locations[0];
    const subscription = subscription_id
      ? await getSubscriptionById(subscription_id)
      : {};
    const qb = qb_recurring_data_ref
      ? await getQBInvoicesRecId(qb_recurring_data_ref)
      : {};

    return {
      ...tourData,
      subscription,
      qb,
      qb_recurring_transaction_id,
    };
  };

  const getTourData = async tours => {
    const results = await Promise.all(tours.map(getCustomerData));
    return results;
  };

  useEffect(() => {
    const loadTourData = async () => {
      setData(filteredByCompanyName);
      setIsLoading(true);

      if (filteredByCompanyName.length > 0 && !isAdmin && !status) {
        const tourData = await getTourData(filteredByCompanyName);
        setData(tourData);
        setStatus(true);
      }
      setIsLoading(false);
    };
    loadTourData();
  }, [filteredByCompanyName]);

  useEffect(() => {
    getHostingLastRunTime().then(res => setLastRunTime(res));
  }, []);

  const handleStartTour = e => e.stopPropagation();

  const sortDataByCreationDate = (a, b, sortOrder) => {
    const dateA = new Date(a.locations[0].description.CREATED_AT);
    const dateB = new Date(b.locations[0].description.CREATED_AT);
    if (sortOrder === 'asc') {
      return dateA - dateB;
    } else if (sortOrder === 'desc') {
      return dateB - dateA;
    } else {
      throw new Error("Sort order should be 'asc' or 'desc'.");
    }
  };

  const handleSortOrderChange = () => {
    setSortOrder(order => (order === 'asc' ? 'desc' : 'asc'));
    setIsSortedAsc(prevState => !prevState);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatusChange = event => {
    const status = event.target.value;
    setSelectedStatus(status);
    setIsSortingByStatus(Boolean(status));
  };

  const filterDataByStatus = (data, status) => {
    if (!status) return data;

    return data.filter(item => {
      const itemStatus = item.locations[0].hosting_status;
      return itemStatus === status;
    });
  };

  const sortDataByStatus = (a, b, sortOrderByStatus) => {
    const statusA = a.locations[0].hosting_status;
    const statusB = b.locations[0].hosting_status;

    const indexA = statusOrder.indexOf(statusA);
    const indexB = statusOrder.indexOf(statusB);

    if (indexA === -1 || indexB === -1) {
      throw new Error(
        'Statuses should be one of the predefined statusOrder values',
      );
    }

    if (sortOrderByStatus === 'asc') {
      return indexA - indexB;
    } else if (sortOrderByStatus === 'desc') {
      return indexB - indexA;
    } else {
      throw new Error("Sort order should be 'asc' or 'desc'.");
    }
  };

  const sortData = useCallback(
    data => {
      const filteredData = filterDataByStatus(data, selectedStatus);
      return filteredData.sort((a, b) => {
        if (isSortingByStatus) {
          const statusComparison = sortDataByStatus(a, b, sortOrderByStatus);
          if (statusComparison !== 0) {
            return statusComparison;
          }

          return sortDataByCreationDate(a, b, sortOrder);
        } else {
          return sortDataByCreationDate(a, b, sortOrder);
        }
      });
    },
    [sortOrder, sortOrderByStatus, isSortingByStatus, selectedStatus],
  );

  // const paginatedData = (filteredData) =>
  //   filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const filteredData = useMemo(
    () => sortData(data),
    [data, sortData, selectedStatus],
  );

  // const currentPageData = useMemo(() => paginatedData(filteredData), [filteredData, page, rowsPerPage]);

  const paginatedData = data =>
    sortData(data).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  if (locations.loading) {
    return <div className={classes.loadingStyle}>Loading...</div>;
  }

  if (data.length === 0) {
    return (
      <div className={classes.skeleton}>
        <Skeleton height="100%" />
        <p className={classes.skeletonContent}>No data available</p>
      </div>
    );
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      {isAdmin && (
        <>
          {lastRunTime ? (
            <p>Last data update: {new Date(lastRunTime).toLocaleString()}</p>
          ) : (
            <p>Loading...</p>
          )}
        </>
      )}
      <PerfectScrollbar>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderArrowCell} />
                <TableCell className={classes.tableHeaderCell}>
                  Product
                </TableCell>
                {/* <TableCell className={classes.tableHeaderCell}> */}
                {/*   <div>Full Price</div> */}
                {/*   <div className={classes.subTitle}>Monthly / Annually</div> */}
                {/* </TableCell> */}
                <TableCell className={classes.tableHeaderCell}>
                  Customer Email
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  Creation Date
                  <Sort
                    onClick={handleSortOrderChange}
                    className={clsx(
                      classes.sortIcon,
                      isSortedAsc && classes.sortIconAsc,
                    )}
                  />
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  Start Date
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  Next Billing Date
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  Invoice Price
                </TableCell>
                {/* {isAdmin && ( */}
                {/*   <TableCell className={classes.tableHeaderCell}> */}
                {/*     Comments */}
                {/*   </TableCell> */}
                {/* )} */}
                <TableCell className={classes.tableHeaderCell}>
                  <div className={classes.statusCell}>
                    <div>Status</div>
                    <Tooltip
                      classes={{ tooltip: classes.tooltip }}
                      title={'Click on status button for subscription managing'}
                    >
                      <img
                        className={classes.statusImg}
                        src={`/static/icons/unpaid.svg`}
                        alt={'Info'}
                      />
                    </Tooltip>
                    <FormControl className={classes.formControl}>
                      <Select
                        value={selectedStatus}
                        onChange={handleStatusChange}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ 'aria-label': 'Sort by status' }}
                      >
                        <MenuItem value="">
                          <em>All</em>
                        </MenuItem>
                        {statusOrder.map(status => (
                          <MenuItem key={status} value={status}>
                            {status}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData(data).map((tour, index) => (
                <React.Fragment key={tour.id}>
                  <TableRow
                    hover
                    onClick={event => toggleOpenItem(event, tour.id)}
                    className={`${
                      tour.locations[0].description.STATUS === 'DISABLED' &&
                      classes.disabledTour
                    }`}
                  >
                    <TableCell className={classes.tableArrowCell}>
                      {openedItemId === tour.id ? (
                        <ArrowDropDownIcon />
                      ) : (
                        <ArrowRightIcon />
                      )}
                    </TableCell>
                    <TableCell className={classes.tablePropertyCell}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div className={classes.tourNumber}>
                            {`${page * rowsPerPage + index + 1}.`}
                          </div>
                          <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            title={tour.multilocationName}
                          >
                            <div className={classes.multilocationName}>
                              {tour.multilocationName}
                            </div>
                          </Tooltip>
                          {(tour.locations[0].qb_recurring_transaction_id ||
                            tour.locations[0].subscription_id) && (
                            <img
                              className={classes.logoImg}
                              src={`/static/icons/${
                                tour.locations[0].qb_recurring_transaction_id
                                  ? 'quickbooks'
                                  : 'stripe'
                              }-logo.svg`}
                              alt={'Logo'}
                            />
                          )}
                        </div>
                        <a
                          href={`${process.env.PLAYER_URL}?locationId=${tour.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={handleStartTour}
                        >
                          <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            title={'Start tour'}
                          >
                            <PlayCircleOutlineRounded
                              fontSize="medium"
                              className={
                                tour.locations[0].is_photography_tour
                                  ? classes.startTourButtonGreen
                                  : classes.startTourButtonBlue
                              }
                            />
                          </Tooltip>
                        </a>
                      </Box>
                    </TableCell>
                    {/* <TableCell className={classes.tableCell}> */}
                    {/*   {`$${ */}
                    {/*     tour.locations.length * MONTHLY_PRICE_PER_LOCATION */}
                    {/*   } / $${ */}
                    {/*     tour.locations.length * ANNUALLY_PRICE_PER_LOCATION */}
                    {/*   }`} */}
                    {/* </TableCell> */}
                    <TableCell className={classes.tableCell}>
                      {(tour.locations[0].qb_recurring_transaction_id ||
                        tour.locations[0].subscription_id) &&
                      tour.locations[0].hosting_customer_email
                        ? tour.locations[0].hosting_customer_email
                        : '-'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {tour.locations[0].description.CREATED_AT
                        ? dateConverter(
                            tour.locations[0].description.CREATED_AT,
                          )
                        : '-'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {(tour.locations[0].qb_recurring_transaction_id ||
                        tour.locations[0].subscription_id) &&
                      tour.locations[0].hosting_start_date
                        ? dBDateConverter(tour.locations[0].hosting_start_date)
                        : '-'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {(tour.locations[0].qb_recurring_transaction_id ||
                        tour.locations[0].subscription_id) &&
                      tour.locations[0].hosting_next_billing_date
                        ? dBDateConverter(
                            tour.locations[0].hosting_next_billing_date,
                          )
                        : '-'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {(tour.locations[0].qb_recurring_transaction_id ||
                        tour.locations[0].subscription_id) &&
                      tour.locations[0].hosting_invoice_price
                        ? `$${tour.locations[0].hosting_invoice_price}`
                        : '-'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {tour.locations[0].qb_recurring_transaction_id ? (
                        <QuickBooksStatusChip
                          isAdmin={isAdmin}
                          qbRecurringTransactionId={
                            tour.locations[0].qb_recurring_transaction_id
                          }
                          invoiceStatus={
                            tour.locations[0].qb_recurring_transaction_id &&
                            tour.locations[0].hosting_status
                          }
                          onClick={e => e.stopPropagation()}
                        />
                      ) : (
                        !tour.locations[0].qb_recurring_transaction_id && (
                          <StripeDialog
                            subsStatus={
                              tour.locations[0].subscription_id &&
                              tour.locations[0].hosting_status
                            }
                            subsId={tour.locations[0].subscription_id}
                            customerId={tour.locations[0].hosting_customer_id}
                            onStateChange={handleStateChangeInPaymentDialog}
                            isActive={
                              tour.locations[0].hosting_status === 'active'
                            }
                            isPaused={
                              tour.locations[0].hosting_status === 'paused'
                            }
                            isCanceled={
                              tour.locations[0].hosting_status === 'canceled'
                            }
                            onClick={e => e.stopPropagation()}
                          />
                        )
                      )}
                      {isLoading &&
                        (tour.locations[0].qb_recurring_transaction_id ||
                          tour.locations[0].subscription_id) && (
                          <CircularProgress
                            className={classes.spinner}
                            size={20}
                          />
                        )}
                      {/* {tour.mainLocation.hosting_status === 'canceled' && ( */}
                      {/*   <div className={classes.subTitle}> */}
                      {/*     (subscription was active until{' '} */}
                      {/*     {timeConverter(tour.subscription.current_period_end)}) */}
                      {/*   </div> */}
                      {/* )} */}
                      {/* {tour.subscription.status === 'past_due' && ( */}
                      {/*   <div className={classes.subTitle}> */}
                      {/*     (your paid period ended on{' '} */}
                      {/*     {timeConverter(tour.subscription.current_period_end)} */}
                      {/*     ) */}
                      {/*   </div> */}
                      {/* )} */}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={9}
                    >
                      <Collapse
                        in={openedItemId === tour.id}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box margin={1}>
                          <Table size="small" aria-label="purchases">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  className={classes.tableSubRowHeaderLocation}
                                >
                                  Location
                                </TableCell>
                                {/* <TableCell */}
                                {/*   className={classes.tableSubRowHeaderPrice} */}
                                {/* > */}
                                {/*   Monthly price */}
                                {/* </TableCell> */}
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                {/* {isAdmin && <TableCell />} */}
                                <TableCell />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {tour.locations.length > 0 &&
                                tour.locations.map((location, index) => (
                                  <TableRow
                                    hover
                                    key={location.id}
                                    className={`${classes.tableRow} ${
                                      location.description.STATUS ===
                                        'DISABLED' && classes.disabledLocation
                                    }`}
                                  >
                                    <TableCell
                                      className={`${classes.tableCell} ${
                                        index === tour.locations.length - 1
                                          ? classes.lastCell
                                          : ''
                                      } ${classes.tableSubRowLocation}`}
                                    >
                                      {location.name}
                                    </TableCell>
                                    {/* <TableCell */}
                                    {/*   className={`${classes.tableCell} ${ */}
                                    {/*     index === tour.locations.length - 1 */}
                                    {/*       ? classes.lastCell */}
                                    {/*       : '' */}
                                    {/*   } ${classes.tableSubRowPrice}`} */}
                                    {/* > */}
                                    {/*   {`$${MONTHLY_PRICE_PER_LOCATION}`} */}
                                    {/* </TableCell> */}
                                    <TableCell
                                      className={`${classes.tableCell} ${
                                        index === tour.locations.length - 1
                                          ? classes.lastCell
                                          : ''
                                      }`}
                                    />
                                    <TableCell
                                      className={`${classes.tableCell} ${
                                        index === tour.locations.length - 1
                                          ? classes.lastCell
                                          : ''
                                      }`}
                                    />
                                    <TableCell
                                      className={`${classes.tableCell} ${
                                        index === tour.locations.length - 1
                                          ? classes.lastCell
                                          : ''
                                      }`}
                                    >
                                      {/* {location.description.CREATED_AT */}
                                      {/*   ? dateConverter( */}
                                      {/*       location.description.CREATED_AT, */}
                                      {/*     ) */}
                                      {/*   : '-'} */}
                                    </TableCell>
                                    <TableCell
                                      className={`${classes.tableCell} ${
                                        index === tour.locations.length - 1
                                          ? classes.lastCell
                                          : ''
                                      }`}
                                    />
                                    <TableCell
                                      className={`${classes.tableCell} ${
                                        index === tour.locations.length - 1
                                          ? classes.lastCell
                                          : ''
                                      }`}
                                    />
                                    <TableCell
                                      className={`${classes.tableCell} ${
                                        index === tour.locations.length - 1
                                          ? classes.lastCell
                                          : ''
                                      }`}
                                    />
                                    <TableCell
                                      className={`${classes.tableCell} ${
                                        index === tour.locations.length - 1
                                          ? classes.lastCell
                                          : ''
                                      }`}
                                    />
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
            <TableFooter className={classes.footer}>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  colSpan={9}
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                    classes: { root: classes.selectRoot },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </PerfectScrollbar>
    </Card>
  );
};

StripesTable.propTypes = {
  className: PropTypes.string,
};

StripesTable.defaultProps = {
  className: '',
};

export default StripesTable;
