import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

import { makeStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import toPercent from '~/utils/toPercent';

const useStyles = makeStyles(() => ({
  deviceItem: {
    fontWeight: 'bold',
    cursor: 'pointer',

    '&:hover': {
      background: 'rgba(54, 166, 253, 0.15)',
      borderRadius: '10px',
    },

    '&>.icon': {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      width: '20%',
    },

    '& .mobile-icon': {
      backgroundImage: 'url(/static/icons/mobile-device.svg)',
    },
    '& .desktop-icon': {
      backgroundImage: 'url(/static/icons/desktop-device.svg)',
    },
  },
  container: {
    marginLeft: '4px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: '2 2 auto',
  },
}));

const ViewsByDevicesInfoCard = ({
  loading = false,
  mobilePercent,
  desktopPercent,
  mobileCount,
  desktopCount,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx('mb-3 bg-white', classes.container)}>
      {/* <span className="font-bold text-xs">Views by devices</span> */}
      {loading ? (
        <Skeleton
          style={{
            height: '42%',
            marginTop: '38px',
            marginLeft: '10px',
            width: '86%',
          }}
        />
      ) : (
        <div className="w-full flex flex-wrap flex-grow">
          <Tooltip title={`Total views: ${mobileCount}`} placement="top">
            <div
              className={clsx('w-1/2 flex justify-center', classes.deviceItem)}
            >
              <div
                className="icon mobile-icon"
                style={{ marginRight: '10px', width: '10%' }}
              />
              <div className="flex flex-col justify-center">
                <div className="text-2xl">
                  {Math.round(toPercent(mobilePercent))}%
                </div>
                <div className="text-xs">Mobile</div>
              </div>
            </div>
          </Tooltip>

          <Tooltip title={`Total views: ${desktopCount}`} placement="top">
            <div
              className={clsx('w-1/2 flex justify-center', classes.deviceItem)}
              // style={{paddingRight: '10px'}}
            >
              <div
                className="icon desktop-icon"
                style={{ marginRight: '10px' }}
              />

              <div className="flex flex-col justify-center">
                <div className="text-2xl">
                  {Math.round(toPercent(desktopPercent))}%
                </div>
                <div className="text-xs">Desktop</div>
              </div>
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

ViewsByDevicesInfoCard.propTypes = {
  loading: PropTypes.bool,
  mobilePercent: PropTypes.number,
  desktopPercent: PropTypes.number,
  mobileCount: PropTypes.number,
  desktopCount: PropTypes.number,
};

ViewsByDevicesInfoCard.defaultProps = {
  loading: false,
  mobilePercent: 0,
  desktopPercent: 0,
  mobileCount: 0,
  desktopCount: 0,
};

export default ViewsByDevicesInfoCard;
